/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const REGISTER_USER = "REGISTER_USER";
export const TOKEN = "TOKEN";

/* AGENCY */
export const AGENCY = "AGENCY";

/* CARS */
export const CARS = "CARS";

/* INBOX */
export const INBOX = "INBOX";
export const SHORTCUTS = "SHORTCUTS";

export const RESERVATION = "RESERVATION";

export const TEMPLATE = "TEMPLATE";

export const LANDING_PAGE = "LANDING_PAGE";

/* MENU */
export const MENU_HIDDEN = "menu-default main-hidden sub-hidden";
export const MENU_EXPOSED = "menu-default sub-hidden";
